<template>
  <v-dialog v-model="active" :persistent="true" width="auto" max-width="700px">
    
    <base-material-card class="pa-0">
      <template v-slot:after-heading>
        <div class="card-title px-4 pt-8 text-h2">
          {{ drugData.name }}
          <v-icon :color="drugData.color" large class="mr-2 mb-2">mdi-circle</v-icon>
        </div>
      </template>

      <v-divider class="mb-0"></v-divider>

      <v-card-text style="max-height: 400px;" class="overflow-auto px-10">
        <div>
          <h3 class="text-h3 primary--text mb-0">
            {{ $t("Recomendacion") }}
          </h3>
          <div class="pl-4">
            {{ drugData.recomendation }}
          </div>
          <v-divider></v-divider>
        </div>
        <div>
          <h4 class="text-h3 primary--text mb-2">
            {{ $t("farmacogenetica") }}
          </h4>
          <div class="pl-4">
            <h4 class="text-h4 primary--text ">
              {{ $t("Substrato de") }}
            </h4>
            <div
              v-for="g in drugData.drugSubstratOfGenesResults"
              :key="g.gene"
              class="pl-4"
            >
              <gene-results-detailed :geneResults="g" />
            </div>
            <br />

            <h4 class="text-h4 primary--text ">{{ $t("Inhibe") }}</h4>
            <div class="d-flex flex-wrap pl-4">
              <a class="text-h5" v-for="el in drugData.drugInhibits" :key="el.key">
                <ActiveChip entity="gene" :elementId="el.id" class="mx-2" />
              </a>
            </div>
            <br />

            <h4 class="text-h4 primary--text">
              {{ $t("Induce") }}
            </h4>
            <div class="d-flex flex-wrap">
              <a
                class="pl-4 darkgray--text"
                v-for="el in drugData.drugInduces"
                :key="el.gen"
              >
                <ActiveChip entity="gene" :elementId="el.gen" class="mx-2" />
              </a>
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div>
          <h3 class="text-h3 primary--text ">
            {{ $t("nombres comerciales") }}
          </h3>
          <div class="pl-4">
            {{ drugData.brandNames }}
          </div>
          <v-divider></v-divider>
        </div>

        <div>
          <h3 class="text-h3 primary--text mb-4">
            {{ $t("alternativas por categoria") }}
          </h3>

          <div class="pl-4 my-4">
            <h3 class="text-h4 primary--text ">
              {{ $t("subgrupo farmacologico") }}
            </h3>
  
            <div v-for="cat in drugData.atc3Alternatives" :key="cat.key">
              - {{ cat.name }}
              <div class="d-flex flex-wrap">
                <a
                  class="mx-2 darkgray--text"
                  v-for="alternative in cat.alternatives"
                  :key="alternative.id"
                >
                  <ActiveChip entity="drug" :elementId="alternative.id" />
                </a>
              </div>
            </div>
          </div>

          <div class="pl-4 my-4">
            <h4 class="text-h4 primary--text ">
              {{ $t("subgrupo quimico") }}
            </h4>
            <div v-for="cat in drugData.atc4Alternatives" :key="cat.key">
              - {{ cat.name }}
              <div class="d-flex flex-wrap">
                <a
                  class="mx-2 darkgray--text"
                  v-for="alternative in cat.alternatives"
                  :key="alternative.id"
                >
                  <ActiveChip entity="drug" :elementId="alternative.id" />
                </a>
              </div>
            </div>
          </div>
          <v-divider></v-divider>
        </div>

        <div v-if="drugData.hasTherapeuticUse">
          <h3 class="text-h3 primary--text ">
            {{ $t("therapeutic_use") }}
          </h3>
          <p>
            {{ drugData.therapeuticUse }}
          </p>
        </div>

        <div v-if="drugData.hasIndications" class="pl-4">
          <h3 class="text-h4 primary--text ">
            {{ $t("indicaciones") }}
          </h3>
          <p>
            {{ drugData.drugIndications }}
          </p>
        </div>

        <div v-if="drugData.hasOffLabelUse" class="pl-4">
          <h3 class="text-h4 primary--text">
            {{ $t("uso no etiquetado") }}
          </h3>
          <p>
            {{ drugData.offLabelUse }}
          </p>
        </div>
      </v-card-text>

      <v-card-actions class="d-flex justify-space-around py-6" fluid>
        <v-btn color="darkprimary" rounded class="" @click="emitClose">
          {{ $t("Cerrar") }}
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-dialog>
</template>

<script>
import ActiveChip from "@/components/mylogy/ActiveChip";
import GeneResultsDetailed from "@/components/mylogy/GeneResultsDetailed.vue";

export default {
  name: "DrugResultDialog",
  components: { ActiveChip, GeneResultsDetailed },
  props: ["active", "drugData"],
  methods: {
      emitClose() {
          this.$emit('close')
      }
  }
};
</script>
