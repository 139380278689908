<template>
<!-- AQUI INSERTASE O ELEMENTO QUE SE QUEIRA POR DE FONDO -->
<div class="fondo-custom">
  <image src="~@/assets/img/fondo1.png" class=""></image>
</div>
</template>

<script>
export default {
  computed: {
    cssVars() {
      return {
        "--detalle-color": `#${this.color}`
      };
    }
  }
}
</script>

<style lang="scss" scoped>
.fondo-custom {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: none;
}
v-image {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
</style>
