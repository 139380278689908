<template>
  <v-dialog :persistent="true" v-model="active" width="auto" max-width="700px">
    <base-material-card id="gene-result-dialog" class="pa-0">
      <v-container class="py-8 px-0">
        <v-card-text class="card-title primary--text text-h2 py-0 px-6">
          {{ geneData.nameResult }}
          <v-icon :color="geneData.color" large class="mr-2 mb-2"
            >mdi-circle</v-icon
          >
        </v-card-text>
        <v-card-text class="card-title primary--text text-h3 py-0 px-6">
          {{ geneData.name }}
        </v-card-text>
        <v-row>
          <v-col
            ><v-card-text class="text-h4 py-0 px-6">
              <strong>PHARMGKB:</strong> {{ geneData.genePharmgkb }}
            </v-card-text></v-col
          >
          <v-col
            ><v-card-text class="text-h4 py-0">
              <strong>OMIM:</strong> {{ geneData.geneOMIM }}
            </v-card-text></v-col
          >
          <v-col
            ><v-card-text class="text-h4 py-0">
              <strong>LOCUS:</strong> {{ geneData.geneLocus }}
            </v-card-text></v-col
          >
        </v-row>
        <v-card-text class="text-h4 py-0 px-6">
          <strong>dbSNP ID:</strong> {{ geneData.geneSNPs.join(", ") }}
        </v-card-text>
        <v-card-text class="text-h4 py-0 px-6">
          <strong>ALIAS:</strong> {{ geneData.geneAliases.join(", ") }}
        </v-card-text>
        <v-divider />

        <v-card-text class="py-0">
          <v-container class="pa-0 px-4">
            <div
              v-for="snp in geneData.snpsInvolved"
              :key="snp.snp"
              class="d-flex justify-space-between"
            >
              <span style>
                {{ snp.snp }}
              </span>
              <span style>
                {{ snp.genotype }}
                <v-icon :color="colors[snp.code]" class="mr-2"
                  >mdi-circle</v-icon
                >
              </span>
            </div>
          </v-container>
          <v-divider></v-divider>

          <v-container class="py-0">
            <p>{{ $t(geneData.geneResult.recomendation) }}</p>
            <div class="px-4">
              <div class="left-border mb-2 pl-2 pt-1">
                <strong class="mr-2">{{ $t("Fenotipo") }}: </strong>
                {{ $t(geneData.geneResult.phenotype) }}
              </div>
              <div class="left-border mb-2 pl-2 pt-1">
                <strong class="mr-2">{{ $t("Actividad") }}: </strong>
                {{ $t(geneData.geneResult.activity) }}
              </div>
            </div>
          </v-container>
          <v-divider></v-divider>
             <v-container class="py-0">
            <p>{{ $t(geneData.summary) }}</p>
          </v-container>
        </v-card-text>
      </v-container>

      <v-card-actions class="d-flex justify-space-around py-6 mt-8" fluid>
        <v-btn color="darkprimary" rounded depressed @click="emitClose">
          {{ $t("Cerrar") }}
        </v-btn>
      </v-card-actions>
    </base-material-card>
  </v-dialog>
</template>

<script>
export default {
  name: "GeneResultDialog",
  components: {},
  props: ["active", "geneData"],
  data() {
    return {
      colors: ["grey", "grey", "green", "yellow", "orange", "red"]
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    }
  }
};
</script>
<style lang="scss" scoped>
.gene-card {
  box-shadow: 0 5px 25px -18px #00000044, 0 -10px 20px inset #00000006;
  width: 100%;
  border: solid 2px #f3f3f3;

  /*Fix para a altura do ultimo item da lista*/
  max-height: 0px;

  .absolute {
    position: absolute;
    right: 10px;
  }
}
.left-border {
  border-left: solid 2px #4d2cb9;
  display: flex;
}
.v-card .v-card__actions {
  background: #ffffff00;
  box-shadow: 0 5px 30px -20px inset #310ab1;
}

#gene-result-dialog .text-h4 {
  font-size: 0.85rem !important;
}
</style>
